<template>
  <el-dialog
    v-model="visible"
    :title="proRowMsg.code ? (isEdit ? '修改商品' : '详情') : '新增商品'"
    width="40%"
    :before-close="handleClose"
  >
    <hr style="margin-bottom: 20px;" />
    <el-form
      :model="proRowMsg"
      label-width="120px"
      :rules="rules"
      ref="elForm"
      v-loading="form.loading"
      :element-loading-text="form.loadText"
    >
      <el-form-item label="商品分类" prop="categoryCode">
        <el-select v-model="proRowMsg.categoryCode">
          <el-option
            :label="item.label"
            :value="item.value"
            v-for="(item, index) in protypes"
            :key="index"
            placeholder="请选择商品类型"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="商品名称" prop="spuName">
        <el-input v-model="proRowMsg.spuName" style="width: 70%;" placeholder="请填写商品称" />
      </el-form-item>
      <el-form-item label="商品图片" prop="pic">
        <el-upload
          :accept="uploadRule.extensions"
          list-type="picture"
          class="avatar-uploader"
          action="#"
          :show-file-list="false"
          :http-request="changeImage"
        >
          <img v-if="proRowMsg.pic" :src="proRowMsg.pic" class="avatar" />
          <el-icon v-else class="avatar-uploader-icon">
            <Plus />
          </el-icon>
        </el-upload>
      </el-form-item>
      <el-form-item label="商品属性" prop="attribute">
        <el-radio-group v-model="proRowMsg.attribute">
          <el-radio :label="1">外采销售 （指商品先采购，后销售，如啤酒）</el-radio>
          <el-radio :label="2">自制销售 （指自制后销售，如鲜榨橙汁）</el-radio>
          <!-- <el-radio :label="3">只做采购 （指仅用于采购，如西瓜）</el-radio> -->
        </el-radio-group>
      </el-form-item>
    </el-form>
    <div style="margin-top: 10px; text-align: center;">
      <el-button type="primary" @click="handleSubmit" style="width: 100px;">确定</el-button>
      <el-button @click="backtrack" style="width: 100px;">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
import { headers, client } from '../../../../lin/util/uploadImg.js'
import { defineComponent, computed, ref, reactive, onMounted } from 'vue'
import API from '../../service/api'
import { ElMessage } from 'element-plus'
import { uploadFileMixin, uploadfileRuleMixin } from '@/mixins/uploadFile.js'

export default defineComponent({
  props: {
    dialogVisible: Boolean,
    proRowMsg: Object,
    protypes: Array,
  },
  setup(props, ctx) {
    const uploadRule = reactive({
      businessType: 'COMMODITY_SKU',
      extensions: '',
    })
    onMounted(() => {
      uploadfileRuleMixin({ businessType: uploadRule.businessType }).then(res => {
        uploadRule.extensions = res.extensions
      })
    })
    const visible = computed(() => {
      return props.dialogVisible
    })
    const form = reactive({
      loadText: '正在火速加载数据...',
      loading: false,
      img: [],
    })

    const changeImage = async file => {
      file.fileName = file.file.name
      const res = await uploadFileMixin(
        file,
        uploadRule.businessType,
        () => {
          form.loadText = '正在火速上传图片...'
          form.loading = true
          form.img.push(file)
          props.proRowMsg.pic = ''
        },
        fileUrl => {
          form.loading = false
          props.proRowMsg.pic = fileUrl
        },
      )
    }

    const rules = reactive({
      spuName: [{ required: true, message: '请填写商品名称', trigger: 'blur' }],
      pic: [{ required: true, message: '请上传商品图片', trigger: 'blur' }],
      categoryCode: [{ required: true, message: '请选择商品分类', trigger: 'blur' }],
      attribute: [{ required: true, message: '请选择商品属性', trigger: 'blur' }],
    })

    const elForm = ref(null)

    const handleSubmit = _.throttle(() => {
      onSubmit()
    }, 2000)

    const onSubmit = async () => {
      if (!elForm) return
      await elForm.value.validate((valid, fields) => {
        if (valid) {
          form.loading = true
          form.loadText = '正在火速处理数据...'
          let saveData = {
            name: props.proRowMsg.spuName,
            categoryCode: props.proRowMsg.categoryCode,
            type: 1, //商品是1
            pic: props.proRowMsg.pic,
            attribute: props.proRowMsg.attribute,
            createBy: JSON.parse(localStorage.getItem('vuex')).user.username,
          }
          if (Reflect.has(props.proRowMsg, 'code')) {
            updateSpuInfo({ ...saveData, code: props.proRowMsg.code })
          } else {
            saveSpuInfo(saveData)
          }
        } else {
          ElMessage.info('请填写完成必要信息')
        }
      })
    }

    //新增数据
    const updateSpuInfo = data => {
      API.updateSpuInfo(data)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('更新成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }
    //修改数据
    const saveSpuInfo = data => {
      API.saveSpuInfo(data)
        .then(res => {
          if (res.code === '200') {
            ElMessage.success('保存成功')
            ctx.emit('update-dialogVisible', { update: false, search: true })
          } else {
            ElMessage.error(res.msg)
          }
        })
        .finally(err => {
          form.loading = false
        })
    }

    const backtrack = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    const handleClose = () => {
      ctx.emit('update-dialogVisible', { update: false })
    }
    return {
      onSubmit,
      handleClose,
      backtrack,
      props: { multiple: true },
      visible,
      elForm,
      rules,
      changeImage,
      form,
      handleSubmit,
      uploadRule,
    }
  },
})
</script>

<style scoped lang="scss">
.avatar-uploader {
  img {
    height: 138px;
    width: 138px;
  }
  ::v-deep .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    border-color: #a1baff;

    .avatar-uploader-icon {
      font-size: 40px;
      color: #8c939d;
      height: 138px;
      width: 138px;
      line-height: 138px;
      text-align: center;
    }
  }
}
</style>
